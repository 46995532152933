import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StorageService } from '../utils/storage.service';
import { User, UserType } from '../shared/types';
import { BuyerConfigService } from './buyer-config/buyer-config.service';
import {
  GetBuyerConfigResponse,
  BuyerConfig,
  AllBuyerConfigSettings,
} from './buyer-config/buyer-config.service.interface';

import {
  AuthFormData,
  LoginResponse,
  ForgotPasswordResponse,
  TokenDataResponse,
  ResetFormData,
  ResetPasswordResponse,
  ForgotFormData,
  AuthState,
  CheckAccessResponse,
  ServiceBuyer,
} from './auth.interface';
import { CompanyService } from '../shared/services/company/company.service';
import { CURRENCY_CODE, UNITS } from '../constants/currency-units';
import {
  CompanyResponse,
  CompanyResponseObject,
} from '../shared/interfaces/company.interface';
import { AuthState as AuthStateInterface } from '@purespectrum1/ui/marketplace/shared/interfaces/auth.interface';
import {
  ChurnZeroConsts,
  ChurnZeroService,
  NEWRELIC,
  Newrelic,
} from '@purespectrum1/ui/tracking';
import { CurrencyService } from '../shared/services/currency-service';
import { CurrencyExchange } from '../shared/types/currency-service-interface';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _baseUrl = environment.pureSpectrum.url;
  private readonly _storageKey = 'authStateStorage';

  /** Flag to persist the auth info to localstorage */
  private _rememberUser = false;

  private _buyerConfig = {
    _id: '',
    advanceTarget: false,
    cmp: false,
    overridCmp: false,
    buyerClient: false,
    enableUrlTranfrmUsr: false,
    decipher: false,
    recontact: false,
    maxinProgress: false,
    bera: false,
    supplierAccordian: false,
    incl_excl: false,
    clickBal: false,
    includeToo: false,
    uniqBuyerEntryLink: false,
    qbp: false,
    samplify: false,
    qlc: false,
    quotaThrottling: false,
    reconciliations: false,
    enableFeot: false,
    enableHyperTarget: false,
    enableScheduledLaunch: false,
    enableSurveyExternalId: false,
    enableNightcrawler: false,
    enableDataQualityQues: false,
    advanceStats: false,
    enableQuestionLibrary: false,
    enableTrafficChannels: false,
    enableInvoiceTab: false,
    enablePriceRecomendation: false,
    enableSupplierGrouping: false,
    enableMarginMax: false,
    enableClickToCalculate: false,
    enableAutoFieldManagement: false,
    enableMultiCountry: false,
    enableManualProcessing: false,
    enableTargetMargin: false,
    enableTargetMarginLaunchAll: false,
    enableTemplate: false,
    enableExtraCoreQualification: false,
    enablePureScoreAndRD: false,
    enableViewByPM: false,
    enableDynamicDash: false,
    enableSurveyDQBattery: false,
    enableNestingQuotaGrouping: false,
    enableAdvanceFeasibilityDropDown: false,
    enableBasicToken: false,
    enablePurePriceRates: false,
    enableLiveRecon: false,
    enableDynamicReports: false,
    enableSurveyDetailsReport: false,
    enableHealthMetrics: false,
    enableProjectLevelDataQuality: false,
    enableDefaultSettingsPureScore: false,
    enableSettingsFpDupeCheck: false,
    enableSettingsFpFraudCheck: false,
    enableDataQualityControls: false,
    enableMandatoryBillingNumber: false,
    enableBillingNumberRules: false,
    enableFilteringSortingBulkEdit: false,
    enableTransactionTestingTool: false,
    enablePMRequired: false,
    enableMultiCountrySingleProjectView: false,
    enableSegmentationClass: false,
    enableClassicSTR: false,
    enableModularSurvey: false,
    enablePaceTool: false,
    enableCreateSurveyV2: false,
    enableSimilarSurveys: false,
  };
  private _allBuyerConfigSettings: AllBuyerConfigSettings = {
    advanceTarget: [],
    cmp: [],
    overridCmp: [],
    buyerClient: [],
    enableUrlTranfrmUsr: [],
    decipher: [],
    recontact: [],
    maxinProgress: [],
    bera: [],
    supplierAccordian: [],
    incl_excl: [],
    clickBal: [],
    includeToo: [],
    uniqBuyerEntryLink: [],
    qbp: [],
    samplify: [],
    qlc: [],
    quotaThrottling: [],
    reconciliations: [],
    enableFeot: [],
    enableHyperTarget: [],
    enableScheduledLaunch: [],
    enableSurveyExternalId: [],
    enableNightcrawler: [],
    enableDataQualityQues: [],
    enableSurveyDQBattery: [],
    advanceStats: [],
    enableQuestionLibrary: [],
    enableTrafficChannels: [],
    enableInvoiceTab: [],
    enablePriceRecomendation: [],
    enableSupplierGrouping: [],
    enableMarginMax: [],
    enableClickToCalculate: [],
    enableAutoFieldManagement: [],
    enableMultiCountry: [],
    enableManualProcessing: [],
    enablePureScoreAndRD: [],
    enableViewByPM: [],
    enableBasicToken: [],
    enablePurePriceRates: [],
    enableBillingNumberRules: [],
    enableMandatoryBillingNumber: [],
    enableTransactionTestingTool: [],
    enablePMRequired: [],
    enableMultiCountrySingleProjectView: [],
    enableSegmentationClass: [],
    enableClassicSTR: [],
    enablePaceTool: [],
    enableCreateSurveyV2: [],
    enableSimilarSurveys: [],
  };
  private _companyConfig = {
    fx: CURRENCY_CODE.US,
  };

  private readonly _defaultCurrencyExchange = {} as CurrencyExchange;
  private _currencyExchange = this._defaultCurrencyExchange;

  private readonly _defaultState: Readonly<AuthState> = {
    token: undefined,
    user: undefined,
    userType: 'none',
    buyerConfig: this._buyerConfig,
    companyConfig: this._companyConfig as CompanyResponseObject,
    rememberUser: false,
    churnZeroKey: '',
    serviceBuyer: undefined,
    allBuyerConfigs: this._allBuyerConfigSettings,
    currencyExchange: this._currencyExchange,
  };

  private _state: Readonly<AuthState> = { ...this._defaultState };

  // Allow subscribing to changes in the auth status
  private _loggedIn$ = new BehaviorSubject<boolean>(false);
  loggedIn$ = this._loggedIn$.asObservable();

  private _loggedInAsServiceBuyer$ = new BehaviorSubject<boolean>(false);
  loggedInAsServiceBuyer$ = this._loggedInAsServiceBuyer$.asObservable();

  get token() {
    return this._state.token;
  }

  get user() {
    return this._state.user;
  }

  get userType() {
    return this._state.userType;
  }

  get buyerConfig() {
    return this._state.buyerConfig;
  }

  get allBuyerConfigs() {
    return this._state.allBuyerConfigs;
  }

  get companyConfig() {
    return {
      ...this._state.companyConfig,
      currencySymbol: UNITS[this._state.companyConfig?.fx || CURRENCY_CODE.US],
    };
  }

  get churnZeroKey() {
    return this._state.churnZeroKey;
  }

  get serviceBuyer() {
    return this._state.serviceBuyer;
  }

  get currencyExchange() {
    return this._state.currencyExchange;
  }

  constructor(
    private _http: HttpClient,
    private _storageService: StorageService,
    private _buyerConfigService: BuyerConfigService,
    private _companyService: CompanyService,
    private _churnZeroService: ChurnZeroService,
    private _currencyService: CurrencyService,
    @Optional() @Inject(NEWRELIC) private _newrelic?: Newrelic
  ) {
    const previousAuth = this._storageService.getItem<AuthState>(
      this._storageKey
    );
    if (previousAuth) {
      this._rememberUser = previousAuth.rememberUser;
      // We were previously storing the TOS state under a separate item, now it's consolidated under
      // the user object, but we don't want to display TOS again to people that have already accepted it
      const tosAccepted = this._storageService.getItem<boolean>('tosAccepted');
      this._storageService.removeItem('tosAccepted');

      if (tosAccepted && previousAuth.user) {
        previousAuth.user.tos = previousAuth.user.tos || {};
        previousAuth.user.tos.accept = true;
      }

      this._setState(previousAuth);
    }
  }

  /**
   * Authenticate the user and handle saving the auth token if rememberme is set.
   */
  login(authData: AuthFormData) {
    return this._http
      .post<LoginResponse>(this._baseUrl + '/user/login', null, {
        headers: {
          skipInterceptor: 'true',
          username: authData.email,
          password: authData.password,
          captcha: authData?.captcha || '',
        },
      })
      .pipe(
        mergeMap((response): any => {
          this._rememberUser = !!authData.rememberme;
          const userType = this.getUserType(response.user);
          this._setState({
            token: response.token,
            user: response.user,
            userType: userType,
            rememberUser: !!authData.rememberme,
            churnZeroKey: response.churnZeroKey,
          });
          this._churnZeroService.sendEvent(
            ChurnZeroConsts.events.login,
            ChurnZeroConsts.eventDescriptions.login
          );
          this._newrelic?.setUserId(`${response.user.id}`);
          this._startIntercom(response);

          const companyConfig$ = this._companyService
            .getCompany(response.user.cmp)
            .pipe(map((companyResponse) => companyResponse.company.pop()));

          const buyerConfig$ = this._buyerConfigService.getBuyerConfig().pipe(
            map((responseConfig: GetBuyerConfigResponse) => {
              const configSetting: BuyerConfig = this._getConfigSetting(
                responseConfig,
                response.user.cmp
              );

              const allBuyerConfigs: AllBuyerConfigSettings =
                this._getAllBuyerConfigSettings(
                  responseConfig,
                  this.getUserType(response.user)
                );

              return {
                buyerConfig: configSetting,
                allBuyerConfigs: allBuyerConfigs,
              };
            })
          );

          return forkJoin({
            config: buyerConfig$,
            companyConfig: companyConfig$,
          }).pipe(
            tap(({ config, companyConfig }) => {
              this._setState({
                buyerConfig: config.buyerConfig,
                allBuyerConfigs: config.allBuyerConfigs,
                companyConfig: companyConfig,
              });
            })
          );
        })
      );
  }
  private _startIntercom(response: LoginResponse) {
    if (environment.production === false) {
      Intercom({
        app_id: 'edws7hvf',
        user_id: `${response.user.id}`,
        name: response.user.usrName,
        email: response.user.eml,
      });
    }
  }

  private _stopIntercom() {
    shutdown();
  }

  /**
   * Logout the active user
   */
  logout() {
    const userId = this.user!.id;
    return this._http
      .delete<LoginResponse>(this._baseUrl + '/logout/' + userId)
      .pipe(
        finalize(() => {
          this._setState(this._defaultState);
          this._storageService.clear();
          this._churnZeroService.disconnect();
          this._newrelic?.setUserId(null);
          this._stopIntercom();
          // Reloading the window to disconnect sockets and load any updated files
          window.location.assign('/');
        })
      );
  }

  /**
   * Clean up the Auth state for inactive/loggedout user
   */
  resetAuthState() {
    this._setState(this._defaultState);
    this._storageService.clear();
  }

  checkUserAccess() {
    const paramOptions = {
      buyer: this._state.user?.buyerAcssLvls || 'none',
      operator: this._state.user?.operatorAcssLvls || 'none',
      supplier: this._state.user?.supplierAcssLvls || 'none',
    };
    let params = new HttpParams({ fromObject: paramOptions });
    return this._http.get<CheckAccessResponse>(
      `${this._baseUrl}/feature/access`,
      { params }
    );
  }

  forgotPassword(forgotPassData: ForgotFormData) {
    return this._http.get<ForgotPasswordResponse>(
      `${this._baseUrl}/checkForgotPasswordMail/${forgotPassData.email}`,
      {
        headers: {
          skipInterceptor: 'true',
          captcha: forgotPassData?.captcha || '',
        },
      }
    );
  }

  getUserDataFromResetToken(resetToken: string) {
    return this._http.get<TokenDataResponse>(
      `${this._baseUrl}/userDataFromResetToken/${resetToken}`,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  resetPassword(resetToken: string, resetPassData: ResetFormData) {
    return this._http.put<ResetPasswordResponse>(
      `${this._baseUrl}/updateuserresetpassword/${resetToken}`,
      resetPassData,
      {
        headers: {
          skipInterceptor: 'true',
        },
      }
    );
  }

  /**
   * Check if the user is currently logged in
   */
  isLoggedIn(): boolean {
    return !!this._state.token;
  }
  isLoggedInAsServiceBuyer(): boolean {
    return !!this._state.serviceBuyer;
  }

  getUserType(user: User): UserType {
    let userType: UserType = 'none';
    if (user.operatorAcssLvls !== 'none') {
      userType = 'operator';
    } else if (user.buyerAcssLvls !== 'none') {
      userType = 'buyer';
    } else if (user.supplierAcssLvls !== 'none') {
      userType = 'supplier';
    }
    return userType;
  }

  // TOS
  updateAcceptedTOS(userId: number, optIn: boolean) {
    const payload = [
      {
        op: 'replace',
        path: '/tos',
        value: true,
      },
      {
        op: 'replace',
        path: '/opt_in',
        value: optIn,
      },
    ];
    return this._http.patch(`${this._baseUrl}/users/${userId}`, payload).pipe(
      tap(() =>
        this._setState({
          user: {
            ...this.user!,
            tos: { accept: true, acceptTS: Date.now() },
          },
        })
      )
    );
  }

  saveBuyerConfig() {
    this._setState({
      buyerConfig: this.buyerConfig,
    });
  }

  setServiceBuyerData(serviceBuyer: ServiceBuyer) {
    this._updateBuyerConfigSettings(serviceBuyer.id);
    this._setState({
      serviceBuyer,
    });
  }

  resetServiceBuyerToken() {
    this._updateBuyerConfigSettings(this._state.user!.cmp);
    this._setState({
      serviceBuyer: undefined,
      currencyExchange: this._defaultCurrencyExchange,
    });
  }

  private _setState(newState: Partial<AuthState>) {
    this._state = { ...this._state, ...newState };
    this._storageService.setItem(this._storageKey, this._state, {
      useLocalStorage: this._rememberUser,
    });

    this._loggedIn$.next(this.isLoggedIn());
    this._loggedInAsServiceBuyer$.next(this.isLoggedInAsServiceBuyer());
  }

  private _getConfigSetting(resposne: any, cmp: number): BuyerConfig {
    const data = resposne.data;
    const obj: any = { _id: data._id };
    for (let key in this._buyerConfig) {
      if (key === '_id') {
        continue;
      }
      if (data.hasOwnProperty(key) && data[key].includes(cmp)) {
        obj[key] = true;
      } else {
        obj[key] = false;
      }
    }
    return obj;
  }

  private _getAllBuyerConfigSettings(
    response: GetBuyerConfigResponse,
    userType: String
  ): AllBuyerConfigSettings {
    if (userType === 'operator') {
      return this._transformFieldsToAllBuyerConfig(response);
    }
    return this._allBuyerConfigSettings;
  }

  private _getFeatureFlag(featureFlag: Array<number> | undefined) {
    return featureFlag || [];
  }

  private _transformFieldsToAllBuyerConfig(
    response: GetBuyerConfigResponse
  ): AllBuyerConfigSettings {
    return {
      advanceTarget: this._getFeatureFlag(response.data.advanceTarget),
      cmp: this._getFeatureFlag(response.data.cmp),
      overridCmp: this._getFeatureFlag(response.data.overridCmp),
      buyerClient: this._getFeatureFlag(response.data.buyerClient),
      enableUrlTranfrmUsr: this._getFeatureFlag(
        response.data.enableUrlTranfrmUsr
      ),
      decipher: this._getFeatureFlag(response.data.decipher),
      recontact: this._getFeatureFlag(response.data.recontact),
      maxinProgress: this._getFeatureFlag(response.data.maxinProgress),
      bera: this._getFeatureFlag(response.data.bera),
      supplierAccordian: this._getFeatureFlag(response.data.supplierAccordian),
      incl_excl: this._getFeatureFlag(response.data.incl_excl),
      clickBal: this._getFeatureFlag(response.data.clickBal),
      includeToo: this._getFeatureFlag(response.data.includeToo),
      uniqBuyerEntryLink: this._getFeatureFlag(
        response.data.uniqBuyerEntryLink
      ),
      qbp: this._getFeatureFlag(response.data.qbp),
      samplify: this._getFeatureFlag(response.data.samplify),
      qlc: this._getFeatureFlag(response.data.qlc),
      quotaThrottling: this._getFeatureFlag(response.data.quotaThrottling),
      reconciliations: this._getFeatureFlag(response.data.reconciliations),
      enableFeot: this._getFeatureFlag(response.data.enableFeot),
      enableHyperTarget: this._getFeatureFlag(response.data.enableHyperTarget),
      enableScheduledLaunch: this._getFeatureFlag(
        response.data.enableScheduledLaunch
      ),
      enableSurveyExternalId: this._getFeatureFlag(
        response.data.enableSurveyExternalId
      ),
      enableNightcrawler: this._getFeatureFlag(
        response.data.enableNightcrawler
      ),
      enableDataQualityQues: this._getFeatureFlag(
        response.data.enableDataQualityQues
      ),
      enableSurveyDQBattery: this._getFeatureFlag(
        response.data.enableSurveyDQBattery
      ),
      advanceStats: this._getFeatureFlag(response.data.advanceStats),
      enableQuestionLibrary: this._getFeatureFlag(
        response.data.enableQuestionLibrary
      ),
      enableTrafficChannels: this._getFeatureFlag(
        response.data.enableTrafficChannels
      ),
      enableInvoiceTab: this._getFeatureFlag(response.data.enableInvoiceTab),
      enablePriceRecomendation: this._getFeatureFlag(
        response.data.enablePriceRecomendation
      ),
      enableSupplierGrouping: this._getFeatureFlag(
        response.data.enableSupplierGrouping
      ),
      enableMarginMax: this._getFeatureFlag(response.data.enableMarginMax),
      enableClickToCalculate: this._getFeatureFlag(
        response.data.enableClickToCalculate
      ),
      enableAutoFieldManagement: this._getFeatureFlag(
        response.data.enableAutoFieldManagement
      ),
      enableMultiCountry: this._getFeatureFlag(
        response.data.enableMultiCountry
      ),
      enableManualProcessing: this._getFeatureFlag(
        response.data.enableManualProcessing
      ),
      enablePureScoreAndRD: this._getFeatureFlag(
        response.data.enablePureScoreAndRD
      ),
      enableViewByPM: this._getFeatureFlag(response.data.enableViewByPM),
      enableBasicToken: this._getFeatureFlag(response.data.enableBasicToken),
      enablePurePriceRates: this._getFeatureFlag(
        response.data.enablePurePriceRates
      ),
      enableBillingNumberRules: this._getFeatureFlag(
        response.data.enableBillingNumberRules
      ),
      enableMandatoryBillingNumber: this._getFeatureFlag(
        response.data.enableMandatoryBillingNumber
      ),
      enableTransactionTestingTool: this._getFeatureFlag(
        response.data.enableTransactionTestingTool
      ),
      enablePMRequired: this._getFeatureFlag(response.data.enablePMRequired),
      enableMultiCountrySingleProjectView: this._getFeatureFlag(
        response.data.enableMultiCountrySingleProjectView
      ),
      enableSegmentationClass: this._getFeatureFlag(
        response.data.enableSegmentationClass
      ),
      enableClassicSTR: this._getFeatureFlag(response.data.enableClassicSTR),
      enablePaceTool: this._getFeatureFlag(response.data.enablePaceTool),
      enableCreateSurveyV2: this._getFeatureFlag(
        response.data.enableCreateSurveyV2
      ),
      enableSimilarSurveys: this._getFeatureFlag(
        response.data.enableSimilarSurveys
      ),
    };
  }

  private async _updateBuyerConfigSettings(
    buyerCompanyId: number
  ): Promise<void> {
    const buyerConfig = await this._getBuyerConfigSettings(buyerCompanyId);
    const companyConfig = await this._getCompanyConfigSettings(buyerCompanyId);
    const currencyExchange = await this._getCurrencyExchange(
      companyConfig.fx || CURRENCY_CODE.US
    );
    this._setState({
      buyerConfig,
      companyConfig,
      currencyExchange,
    });
  }

  private async _getBuyerConfigSettings(
    companyId: number
  ): Promise<BuyerConfig> {
    const buyerConfigResponse: GetBuyerConfigResponse =
      await this._buyerConfigService.getBuyerConfig().toPromise();
    const buyerConfig: BuyerConfig = this._getConfigSetting(
      buyerConfigResponse,
      companyId
    );
    return buyerConfig;
  }

  private async _getCompanyConfigSettings(companyId: number) {
    const {
      company: [companyObj],
    }: CompanyResponse = await this._companyService
      .getCompany(companyId)
      .toPromise();
    return companyObj;
  }

  private async _getCurrencyExchange(fx: number): Promise<CurrencyExchange> {
    if (fx === CURRENCY_CODE.US) {
      return this._defaultCurrencyExchange;
    }
    const response = await this._currencyService
      .getCurrencyExchange(fx)
      .toPromise();
    return response.currencies;
  }

  // This function is specific to market place components inherited from component library
  get getMarketplaceAuth(): AuthStateInterface {
    return {
      token: this._state.token,
      user: this._state.user,
      userType: this._state.userType,
      buyerConfig: this._state.buyerConfig,
      churnZeroKey: this._state.churnZeroKey,
      serviceBuyer: this._state.serviceBuyer,
      companyConfig: this.companyConfig as CompanyResponseObject,
      allBuyerConfigs: this._state.allBuyerConfigs,
    };
  }

  public email(): string {
    return this.user?.eml || 'No user logged in';
  }
}
